import * as React from "react"
import { P } from '../components/paragraph'
import { DRIBBBLE, LINKEDIN } from '../../config'

// markup
const AboutPage = () => {
  return (
    <React.Fragment>
      <h1 className='spacer-s'>Hoj!</h1>

      <P className='spacer-s'>
        I'm Denis, I help companies big and small with design. Sometimes I help them refine their strategies. Sometimes I help them design their products. There was a time when I only gave feedback like there is no tomorrow...
      </P>

      <P>
        ... and I've been doing that for <i>*checks calendar*</i> 10 years now?! Geez. But where you ask? You can check out my <a href={LINKEDIN} target='_blank'>LinkedIn</a> for mor details or <a href={DRIBBBLE} target='_blank'>Dribbble</a> profile in the meantime.
      </P>
    </React.Fragment>
  )
}

export default AboutPage